import React from "react";
import useStyles from "./style"
import logo from "../../assets/img/logo.png"
import appstore from "../../assets/img/appstore.png"
import sectionOne from "../../assets/img/sectionOne.png"
import colorblindType from "../../assets/img/colorblindType.png"
import screenType from "../../assets/img/screenType.png"
import enhancer from "../../assets/img/enhancer.png"
import screenEnhancer from "../../assets/img/screenEnhancer.png"
import simulator from "../../assets/img/simulator.png"
import screenSimulator from "../../assets/img/screenSimulator.png"
import detector from "../../assets/img/detector.png"
import screenDetector from "../../assets/img/screenDetector.png"
import orcaOnMac from "../../assets/img/orcaOnMac.png"
import CursorWhite from "../../assets/svg/cursorWhite";
import barcodeAppstore from "../../assets/img/barcodeAppstore.png";
import Instagram from "../../assets/svg/instagram";

const Home = () => {
  const styles = useStyles();
  return (
    <div className={styles.app}>
      <header className={styles.headerApp}>
        <img src={logo} alt="Orca: Colorblind Assistant" className="appLogo" />
      </header>
      <section className={styles.sectionTitle}>
        <h1 className="title">Experience Colors You’ve Never Seen Before</h1>
        <span className="body">Enhance colors for you and see the true beauty of the world!</span>
        <a href="https://apps.apple.com/id/app/orca-colorblind-assistant/id6476919624">
          <img src={appstore} alt="Appstore Orca" className="testflight" />
        </a>
      </section>
      <img src={sectionOne} alt="Features Orca" className="image" />
      <section className={styles.sectionFeatures}>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={colorblindType} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Colorblind <h5 className="square">Type</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Personalized Just For You. Choose your type of colorblindness and sensitivity.</span>
          </div>
          <img src={screenType} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={enhancer} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Enhancer</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Experience colors that you’ve never seen before. The Enhancer mode adjusts the colors of objects to make them appear more vibrant based on colorblind type.</span>
          </div>
          <img src={screenEnhancer} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={simulator} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Simulator</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Simulate how colorblind people sees the world.</span>
          </div>
          <img src={screenSimulator} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={detector} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Detector</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Identify colors around you by simply pointing device's camera.</span>
          </div>
          <img src={screenDetector} alt="Colorblind Type" className="imgFeature" />
        </section>
      </section>
      <section className={styles.sectionMac}>
        <div className="background"></div>
        <div className="content">
          <h6 className="title">Soon in MacOS!</h6>
          <span className="body">
            <span className="text">Unleash the power of simplicity with Orca accessible right from the</span>
            <CursorWhite className="svg"/>
          </span>
          <img src={orcaOnMac} alt="Orca on MacOS" className="imgMac"/>
        </div>
      </section>
      <section className={styles.sectionTry}>
        <div className="column">
          <h3 className="title">TRY ORCA NOW!</h3>
          <span className="body">Enhance colors for you and see the true beauty of the world!</span>
          <a href="https://apps.apple.com/id/app/orca-colorblind-assistant/id6476919624">
            <img src={appstore} alt="Appstore Orca" className="testflight" />
          </a>
        </div>
        <img src={barcodeAppstore} alt="Barcode Tesflight" className="barcode" />
      </section>
      <footer className={styles.footerApp}>
        <div>
          <img src={logo} alt="Orca: Colorblind Assistant" className="appLogo" />
        </div>
        <p className="copy">@ 2023 All rights reserved.</p>
        <a href="https://www.instagram.com/orcabyzen">
          <Instagram />
        </a>
      </footer>
    </div>
  )
};

export default Home;
